/*
 * @Author: yuwenwen
 * @Date: 2022-11-11 17:14:36
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-06 15:10:10
 * @FilePath: \nc-funeral-mobile\src\utils\funeral.js
 */

import wx from 'weixin-js-sdk';
import {
  getWxTicket
} from '@/api/common.js'
import {
  showToast
} from 'vant';
// 微信支付
// 支付配置
export function initPayConfig(result) {
  return new Promise((resolve, reject) => {
    // 初始化配置
    wx.config({
      beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: result.appId, // 必填，企业微信的corpID
      timestamp: result.timeStamp, // 必填，生成签名的时间戳
      nonceStr: result.nonceStr, // 必填，生成签名的随机串
      signature: result.paySign, // 必填，签名，见附录1
      jsApiList: ['chooseWXPay'],
    })
    // 初始化成功后
    wx.ready(() => {
      wx.chooseWXPay({
        timestamp: result.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
        nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
        package: result.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: result.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
        paySign: result.paySign, // 支付签名
        fail() {
          showToast('唤起微信支付失败！')
        },
        complete: function () {
          resolve()
        },
      })
    })
    // error
    wx.error(err => {
      reject(err)
    })
  })
}

// 微信分享
export function wxShare(config) {
  let {
    title,
    desc,
    link,
    imgUrl
  } = config;

  getWxTicket(link).then(res => {
    if (res.data) {
      console.log(res.data)
      wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.appId, // 必填，企业微信的corpID
        timestamp: res.data.timeStamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名，见附录1
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ],
      })

      wx.ready(() => {
        wx.updateAppMessageShareData({
          title,
          desc,
          link,
          imgUrl
        });
        wx.updateTimelineShareData({
          title,
          link,
          imgUrl
        })

      })

    }
  })
}

export function initConfig(config) {
  return new Promise((resolve, reject) => {
    wx.config({
      beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appId, // 必填，企业微信的corpID
      timestamp: config.timeStamp, // 必填，生成签名的时间戳
      nonceStr: config.nonceStr, // 必填，生成签名的随机串
      signature: config.signature, // 必填，签名，见附录1
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
      ],
    })
    wx.ready((wx) => {
      resolve(wx)
    })
    wx.error(err => {
      reject(err)
    })
  })
}

// 微信分享
export function inixWxShare({
  title,
  desc,
  link,
  imgUrl
}) {
  console.log(link, 'linklinklink');
  wx.updateAppMessageShareData({
    title,
    desc,
    link,
    imgUrl
  })
  // wx.onMenuShareAppMessage({ title, desc, link, imgUrl })
  wx.updateTimelineShareData({
    title,
    link,
    imgUrl
  })
  // wx.onMenuShareTimeline({ title, link, imgUrl })

}

// 提取url上的参数(所有参数)
export function getQueryVariables(url) {
  var askIndex = url.indexOf('?');
  var polIndex = url.lastIndexOf('#');
  var askText = '';
  var polText = '';
  polIndex == -1 ? polIndex = url.length : null;
  polText = url.substring(polIndex + 1);
  if (askIndex > -1) {
    askText = url.substring(askIndex + 1, polIndex);
  }
  var obj = {};
  polText.isNotEmpty ? obj['HASH'] = polText : null;
  if (askText != '') {
    askText.split('&').map((item) => {
      var arr = item.split('=');
      obj[arr[0]] = arr[1];
    });
  }
  return obj;
}

/*
 *提取url上的参数(根据key值获取value值) 
 * variable(key值)
 */
export function getQueryVariable(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  let str = null
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      str = pair[1]
      return str
    }
  }
  return str
}

/*
 *获取服务器图片路径
 */
export function getImagesUrl(url) {
  if (url.startsWith('http')) {
    return url;
  } else {
    return process.env.VUE_APP_FILE_PATH + url;
  }
}

/*
 *时间格式转换  
 *time (Date格式)
 *pattern 时间格式
 */
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function getDistance(lat1, lng1, lat2, lng2) {
  var distance_str = "";
  // 四个值都存在进行距离计算
  if (lat1 != undefined && lng1 != undefined && lat2 != null && lng2 != null) {
    lat2 = lat2 * 1
    lng2 = lng2 * 1
    var radLat1 = rad(lat1);
    var radLat2 = rad(lat2);
    var a = radLat1 - radLat2;
    var b = rad(lng1) - rad(lng2);
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * 6378.137; // EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000; //输出为公里
    var distance = s;
    if (distance) {
      if (parseInt(distance) >= 1) {
        distance_str = distance.toFixed(1) + "km";
      } else {
        distance_str = distance * 1000 + "m";
      }
    }
  }
  return distance_str;
}

function rad(d) {
  return d * Math.PI / 180.0;
}

// 判断是否是手机端
export function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

// 去除多位小数
export function NumberMul(arg1, arg2) {
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString();
  try {
      m += s1.split(".")[1].length;
  } catch (e) {
    console.log(e)
  }
  try {
      m += s2.split(".")[1].length;
  } catch (e) {
    console.log(e)
  }

  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

export function NumberAdd(arg1, arg2) {
  var r1, r2, m, n;
  try {
      r1 = arg1.toString().split(".")[1].length
  } catch (e) {
      r1 = 0
  }
  try {
      r2 = arg2.toString().split(".")[1].length
  } catch (e) { 
      r2 = 0 
  } 
  m = Math.pow(10, Math.max(r1, r2))
  n = (r1 >= r2) ? r1 : r2;
  return ((arg1 * m + arg2 * m) / m).toFixed(n);
}

export function validatePhone(val) {
  const reg =
    /^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-8]|18[0-9]|19[0-9])\d{8}$/;
  return reg.test(val);
}