import request from '@/utils/request'

const root = '/h5/bus/'
//  员工绑定
export function login(data) {
  return request({
    url: root + 'index/bindStaff',
    method: 'post',
    data,
  })
}
//  员工-解除绑定   
export function unbindStaff(data) {
  return request({
    url: root + 'index/unbindStaff',
    method: 'post',
    data,
  })
}
// 当前员工信息
export function staffInfo(params) {

  return request({
    url: '/auth/staff/info',
    // headers: {
    //   isToken: false
    // },
    method: 'get',
    params,
  })
}
// 遗体接运 任务列表   Receive
export function ReceiveList(params) {

  return request({
    url: root + 'dispatch/list',
    // headers: {
    //   isToken: false
    // },
    method: 'get',
    params,
  })
}
// 遗体接运 我的任务列表
export function ReceiveMyList(params) {

  return request({
    url: root + 'dispatch/my/list',
    // headers: {
    //   isToken: false
    // },
    method: 'get',
    params,
  })
}
// 遗体接运 详情
export function ReceiveDetails(applyId) {
  return request({
    url: root + 'dispatch/service/' + applyId,
    // headers: {
    //   isToken: false
    // },
    method: 'get',
  })
}
// 遗体接运 档案
export function ReceiveFile(deadId) {

  return request({
    url: root + 'dispatch/archives/' + deadId,
    // headers: {
    //   isToken: false
    // },
    method: 'get',
  })
}
// 遗体接运 -出车
export function ReceiveOutCar(applyId) {

  return request({
    url: root + 'dispatch/departure/' + applyId,
    // headers: {
    //   isToken: false
    // },
    method: 'get',
  })
}
// 遗体接运 送达
export function ReceiveDelivery(applyId) {

  return request({
    url: root + 'dispatch/arrive/' + applyId,
    // headers: {
    //   isToken: false
    // },
    method: 'get',
  })
}
// 遗体接运 到馆
export function ReceivePavilion(applyId) {

  return request({
    url: root + 'dispatch/museum/' + applyId,
    // headers: {
    //   isToken: false
    // },
    method: 'get',
  })
}



// 遗体外运-列表(分页)   External transport
export function ETansportList(params) {

  return request({
    url: root + 'send/list',
    // headers: {
    //   isToken: false
    // },
    method: 'get',
    params,
  })
}
// 遗体外运-列表-我的
export function ETansportMyList(params) {

  return request({
    url: root + 'send/my/list',
    // headers: {
    //   isToken: false
    // },
    method: 'get',
    params,
  })
}
// 遗体外运-详情
export function ETansportDetails(applyId) {

  return request({
    url: root + 'send/service/' + applyId,
    // headers: {
    //   isToken: false
    // },
    method: 'get',
  })
}
// 遗体外运-档案
export function ETansportFile(deadId) {

  return request({
    url: root + 'send/archives/' + deadId,
    // headers: {
    //   isToken: false
    // },
    method: 'get',
  })
}
// 遗体外运-出车
export function ETansportOutCar(applyId) {

  return request({
    url: root + 'send/departure/' + applyId,
    // headers: {
    //   isToken: false
    // },
    method: 'get',
  })
}
// 遗体外运-送达
export function ETansportDelivery(applyId) {

  return request({
    url: root + 'send/arrive/' + applyId,
    // headers: {
    //   isToken: false
    // },
    method: 'get',
  })
}
// 遗体外运-到馆
export function ETansportPavilion(applyId) {

  return request({
    url: root + 'send/museum/' + applyId,
    // headers: {
    //   isToken: false
    // },
    method: 'get',
  })
}