<!--
 * @Author: yuwenwen
 * @Date: 2022-11-08 15:00:48
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-01 19:52:29
 * @FilePath: \nc-funeral-mobile\src\views\mine\mine.vue
-->
<template>
  <div class="mine-container">
    <div class="header-bg">
      <img class="default-avatar" src="../../assets/images/default-avatar.png" />
      <div class="nick-name">{{ userInfo.userInfo.username }}</div>
    </div>
    <div class="my-service-box">
      <div class="my-service-header">
        <div>我的服务</div>
        <div class="bangding" v-if="!showPickUp" @click="bindingStaff">绑定员工</div>
        <div class="bangding" v-else-if="showPickUp" @click="unbindingStaff">解除绑定</div>
      </div>
      <div class="my-service">
        <div class="item" @click="toPage('/mine/my/order')">
          <img src="../../assets/images/my-order.png" />
          <div class="text">我的订单</div>
        </div>

        <div class="item" @click="toPage('/obituary/records')">
          <img src="../../assets/images/my-o-history.png" />
          <div class="text">讣告记录</div>
        </div>
        <div class="item" style="margin-right: 0" @click="toPage('/complaint/list')">
          <img src="../../assets/images/my-complaint.png" />
          <div class="text">投诉记录</div>
        </div>
      </div>
    </div>
    <div class="my-service-box" style="top:7.5rem;" v-if="showPickUp">
      <div class="my-service-header">司机服务</div>
      <div class="my-service">
        <div class="item" @click="toPage('/remainPickUp')">
          <img src="../../assets/images/my-transport.png" />
          <div class="text">遗体接运</div>
        </div>
        <div class="item">
          <img src="../../assets/images/my-record.png" @click="toPage('/remainETansport')" />
          <div class="text">遗体外运</div>
        </div>
      </div>
    </div>

    <van-popup v-model:show="LoginShow" :closeable="true" @click-overlay="closePopup()" @click-close-icon="closePopup()">
      <van-form @submit="onSubmit">
        <van-field v-model="username" name="员工工号" label="员工工号" placeholder="员工工号"
          :rules="[{ required: true, message: '请填写员工工号' }]" label-align="center" />
        <van-field v-model="password" type="password" name="密码" label="密码" placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]" label-align="center" />
        <div style="margin: 16px">
          <van-button round block type="primary" native-type="submit">
            点击绑定
          </van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getToken } from "@/store/auth";
import { showSuccessToast, showFailToast } from "vant";
import { login, unbindStaff } from "@/api/remainsPickUp";
export default {
  name: "Mine",
  data() {
    return {
      show: true,
      username: "",
      password: "",
      userInfo: {}
      // showPickUp:false, //接运显隐
    };
  },
  computed: {
    ...mapState(["token"], ["expiredTime"]),
    showPickUp: {
      get() {
        if (this.$store.state.user.staffId) {
          return true;
        } else {
          return false;
        }
      },
    },
    LoginShow: {
      get() {
        return this.$store.state.user.isLogin;
      },
      set(val) {
        return val;
      },
    },
  },
  created() {
    this.$store.commit("TO_LOGIN", false);
    this.userInfo = this.$store.state.user;
  },
  methods: {
    toPage(path) {
      if (getToken()) {
        this.$router.push({ path: path });
      } else {
        // this.LoginShow = true;
        this.$store.commit("TO_LOGIN", true);
      }
    },
    // 绑定员工
    bindingStaff() {
      this.$store.commit("TO_LOGIN", true);
    },
    // 解绑员工
    unbindingStaff() {
      console.log('----------------解绑员工--------------------');
      // 解绑
      unbindStaff()
        .then((res) => {
          if (res.success) {
            showSuccessToast(res.data);
          } else {
            showFailToast(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch("GetInfo");
        });
    },
    closePopup() {
      // 关闭弹窗
      this.$store.commit("TO_LOGIN", false);
    },
    onSubmit() {
      let parameter = {
        staffNo: this.username,
        password: this.password,
      };
      // 绑定员工
      login(parameter)
        .then((res) => {
          if (res.success) {
            showSuccessToast(res.data);
          } else {
            showFailToast(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //关闭登录弹窗
          this.$store.commit("TO_LOGIN", false);
          this.$store.dispatch("GetInfo");
        });
    },
  },
};
</script>
<style lang="less" scoped>
.my-service-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.53333rem;

  .bangding {
    color: #a9a6a6;
    font-size: 13px;
  }
}

::v-deep .van-popup {
  width: 90%;
  padding: 9% 2% 9% 2%;
  box-sizing: border-box;
  border-radius: 2%;
}

::v-deep input:-internal-autofill-selected {
  background-color: transparent;
}

@import "../../assets/styles/mine.less";
</style>