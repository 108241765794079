/*
 * @Author: yuwenwen
 * @Date: 2022-11-14 17:31:42
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-06 10:44:04
 * @FilePath: \nc-funeral-mobile\src\api\index.js
 */
import request from "../utils/request";

const root = '/h5/bus/index/'

// 首页轮播(首页)
export function getBannerList() {
    return request({
        url: root + `carousel`,
        method: 'GET',
    })
}

// 首页菜单 (首页)
export function getMenusList() {
    return request({
        url: root + `menus`
    })
}

// 单位介绍
export function getCompanyIntroduce() {
    return request({
        url: root + `company/introduction`,
        method: 'GET'
    })
}

// 公告列表(首页)
export function getNoticeList() {
    return request({
        url: root + `announcement/list`,
        method: 'GET'
    })
}

// 公告详情
export function getNoticeDetails(id) {
    return request({
        url: root + `announcement/detail/${id}`,
        method: 'GET'
    })
}

// 政策宣传 (首页)
export function getAdvertiseInfomation() {
    return request({
        url: root + `advertiseInformation`,
        method: 'GET'
    })
}

// 政策宣传，政策法规列表
export function getPolicyList(params) {
    return request({
        url: root + `advertiseInformation/list`,
        method: 'GET',
        params
    })
}

// 政策宣传，政策法规详情
export function getPolicyDetails(id) {
    return request({
        url: root + `advertiseInformation/details/${id}`
    })
}

// 推荐礼厅
export function getCeremonyHallList() {
    return request({
        url: root + `vrList`,
        method: 'GET',
    })
}