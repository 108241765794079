<!--
 * @Author: yuwenwen
 * @Date: 2022-11-08 14:59:10
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-03-16 16:42:35
 * @FilePath: \nc-funeral-mobile\src\views\navigator\navigator.vue
-->
<template>
  <div class="map-container">
    <div id="map-container" class="map-container"></div>
    <div class="navigator-bar">
      <div>
        <div class="addr-text">{{ targetAddr }}</div>
        <div class="distance-text" v-if="!address">
          距您{{ distance }}公里 新建区松鹤路723号
        </div>
      </div>
      <div class="navigator-btn" v-if="isDisplayBtn" @click="show = true">导航</div>

    </div>
    <van-action-sheet v-model:show="show" description="请选择已安装APP" :actions="actions" @select="onSelect" />
  </div>
</template>
<script>
import { getDistance } from "@/utils/funeral.js";
import { showFailToast } from "vant";
export default {
  name: "Navigator",
  data() {
    return {
      distance: "",
      address: "",
      targetAddr: "南昌市殡仪馆",
      show: false,
      actions: [{ id: 1, name: '腾讯地图' }, { id: 2, name: '百度地图' }, { id: 3, name: '高德地图' }],
      fromPoints: {
        lat: '',
        lng: '',
      },
      longitude: 115.842079,
      latitude: 28.716471,
      isDisplayBtn:true
    };
  },
  created() {
    let { addr } = this.$route.query;
    this.address = addr;
  },
  mounted() {
    const _this = this;
    this.$nextTick(() => {
      if (this.address) {
        this.targetAddr = this.address;
        _this.getMapInfoByName();
      } else {
        _this.getMapInfo();
      }
    });
  },
  methods: {
    //默认殡仪馆
    getMapInfo() {
      let Bmap = window.BMapGL;
      this.map = new Bmap.Map("map-container");
      // 初始化地图，设置中心点坐标和地图级别
      const point = new Bmap.Point(this.longitude, this.latitude);
      const marker = new Bmap.Marker(point); // 创建标注
      this.map.addOverlay(marker); // 将标注添加到地图中
      const content = "南昌市殡仪馆";
      const label = new Bmap.Label(content, {
        // 创建文本标注
        position: point, // 设置标注的地理位置
        offset: new Bmap.Size(-45, 5), // 设置标注的偏移量
      });
      label.setStyle({
        // 设置label的样式
        color: "#000",
        background: "transparent",
        fontSize: "15px",
        border: "none",
      });
      this.map.addOverlay(label); // 将标注添加到地图中
      this.map.centerAndZoom(point, 15);
      this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      this.getDistance(this.longitude, this.latitude);
    },

    // 根据名称显示中心点
    getMapInfoByName() {
      let _this = this;
      let Bmap = window.BMapGL;
      this.map = new Bmap.Map("map-container");
      this.map.centerAndZoom(this.address, 15);
      this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放

      var localSearch = new Bmap.LocalSearch(this.map);
      localSearch.setSearchCompleteCallback(function (searchResult) {
        var poi = searchResult.getPoi(0);
        if (poi) {
          _this.isDisplayBtn = true;
          var marker = new Bmap.Marker(new Bmap.Point(poi.point.lng, poi.point.lat));  // 创建标注，为要查询的地方对应的经纬度
          _this.longitude = poi.point.lng;
          _this.latitude = poi.point.lat;
          _this.map.addOverlay(marker);
          _this.getDistance(poi.point.lng, poi.point.lat);
        }else{
          _this.isDisplayBtn = false;
          showFailToast(`无法定位位置：${_this.address}`);
        }
      });
      localSearch.search(this.address);
    },

    // 获取当前位置和南昌殡仪馆的距离
    getDistance(lng, lat) {
      let _this = this;
      let Bmap = window.BMapGL;
      var geolocation = new Bmap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        _this.fromPoints.lat = r.latitude;
        _this.fromPoints.lng = r.longitude;
        _this.distance = getDistance(lat, lng, r.latitude, r.longitude);
        console.log(_this.distance, "dis");
      });
    },

    onSelect(item) {
      console.log(item);
      const longitude = this.longitude;
      const latitude = this.latitude;
      const name = "南昌殡仪馆";
      let url = "";
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
      console.log(longitude, latitude, name, isiOS, isAndroid, url)
      if (isAndroid) {
        switch (item.id) {
          // 下面是拼接url,不同系统以及不同地图都有不同的拼接字段
          case 1:
            // 注意referer=xxx的xxx替换成你在腾讯地图开发平台申请的key
            url = `//apis.map.qq.com/uri/v1/marker?marker=coord:${latitude},${longitude};addr:${name}`;
            break;
          case 2:
            url = `//api.map.baidu.com/direction?origin=latlng:${this.fromPoints.lat},${this.fromPoints.lng}|name=我的位置&destination=latlng:${latitude},${longitude}|name=${name}&mode=driving&region=${name}&output=html&src=webapp.baidu.openAPIdemo&coord_type=wgs84`;
            break;
          case 3:
            url = `//uri.amap.com/navigation?from=${this.fromPoints.lng},${this.fromPoints.lat},我的位置&to=${longitude},${latitude},${name}&mode=car&policy=1&src=mypage&coordinate=gaode&callnative=0`;
            break;
          default:
            break;
        }
        if (url !== "") {
          window.open(url, "_self");
        }
      } else if (isiOS) {
        switch (item.id) {
          case 1:
            url = `qqmap://map/geocoder?coord=${latitude},${longitude}&title=${name}&referer=K3SBZ-ECOCU-UL6VR-BQDSE-Q77FH-ZIFDR`;
            break;
          case 2:
            url = `baidumap://map/marker?location=${latitude},${longitude}&title=${name}&content=${name}&src=ios.baidu.openAPIdemo&coord_type=gcj02`;
            break;
          case 3:
            url = `iosamap://viewMap?sourceApplication=applicationName&poiname=${name}&lat=${latitude}&lon=${longitude}&dev=0`;
            break;
          default:
            break;
        }
        if (url !== "") {
          window.open(url, "_self");
        }
      }
    },
  },
};
</script>