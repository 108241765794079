<!--
 * @Author: yuwenwen
 * @Date: 2022-11-08 14:59:50
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-03-13 15:49:03
 * @FilePath: \nc-funeral-mobile\src\views\progress\progress.vue
-->
<template>
  <div class="page-container progress-page">
    <div class="page-header"></div>
    <div class="death-info-box">
      <div class="box-header">
        <div class="death-name">{{processInfo.deadName}}</div>
        <div class="business">
          <div class="business-name">{{ processInfo.serviceType || '无' }}</div>
          <div class="business-tag">当前业务</div>
        </div>
      </div>
      <div class="box-content">
        <div class="title">逝者信息</div>
        <div class="item">
          <div class="label">身份证号：</div>
          <div>{{processInfo.cardCode || '-'}}</div>
        </div>
        <div class="item">
          <div class="label">亡故时间：</div>
          <div>{{processInfo.deathDate || '-'}}</div>
        </div>
        <div class="title">待办人信息</div>
        <div class="item">
          <div class="label">姓名：</div>
          <div>{{processInfo.contactName || '-'}}</div>
        </div>
        <div class="item">
          <div class="label">电话：</div>
          <div>{{processInfo.contactPhone || '-'}}</div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="death-info-box business-progress">
      <div class="box-content" style="padding-bottom:24px">
        <div class="title">业务进度</div>
        <div>
          <div
            v-for="(item, index) in progressList"
            :key="index"
            class="progress-box"
          >
            <!-- 左侧图标 -->
            <div class="left">
              <!-- 圆 -->
              <div class="active-circle">
                 <div class="active-circle-inline"></div>
              </div>
              <!-- 竖线 -->
              <div class="dashed-line" v-if="index != progressList.length-1"></div>
            </div>

            <!-- 右侧内容 -->
            <div class="progress-content">
               <div>{{ item.serviceTypeName }}</div>
               <div class="time">{{ item.status=='0'?'未完成':'已完成' }}  {{ item.updateTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {scanning} from '@/api/common.js'
export default {
  name: "Progress",
  data() {
    return {
      processInfo:{},
      progressList:[],
      deadId:'',
    };
  },
  created() {
    let {deadId} = this.$route.query;
    this.deadId = deadId;
    this.scanning();
  },
  mounted() {},
  methods: {
    scanning(){
      scanning(this.deadId).then(res=>{
        console.log(res)
        this.processInfo = res.data.data;
        this.progressList = res.data.progressList
      })
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/styles/mine.less";
</style>