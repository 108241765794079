<!--
 * @Author: yuwenwen
 * @Date: 2022-11-08 14:56:18
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-03-16 11:54:19
 * @FilePath: \nc-funeral-mobile\src\views\index\home.vue
-->
<template>
  <div class="page-container">
    <!-- 轮播图 -->
    <van-swipe indicator-color="white" v-if="bannerList.length > 0">
      <van-swipe-item v-for="(image, index) in bannerList" :key="index">
        <img src="@/assets/images/banner.png" fit="cover" class="swipe-item" />
      </van-swipe-item>
    </van-swipe>
    <van-swipe indicator-color="white" v-else>
      <van-swipe-item>
        <img src="@/assets/images/banner.png" fit="cover" class="swipe-item" />
      </van-swipe-item>
    </van-swipe>
    <!-- 搜索框 -->
    <div class="search-box">
      <div class="input-container">
        <van-search
          placeholder="请输入搜索关键词"
          v-model="keyword"
          @keypress.enter="toSearchPage"
        />
      </div>
      <div>
         <a :href="href" v-if="href">
          <img class="phone-icon" src="../../assets/images/tel-icon.png" />
        </a>
        <img @click="makeCallTips" v-else class="phone-icon" src="../../assets/images/tel-icon.png" />
      </div>
      
    </div>
    <!-- 单位介绍  服务指南 政策法规  投诉举报-->
    <div class="entries-container">
      <div
        v-for="(item, index) in entriesList"
        :key="index"
        @click="toPage(item)"
      >
        <img src="@/assets/images/e-introduce.png" />
        <div class="text">{{ item.name }}</div>
      </div>
    </div>
    <!-- 通知公告 -->
    <div class="notice-container">
      <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :touchable="false"
          :show-indicators="false"
        >
          <van-swipe-item
            v-for="(item, index) in noticeList"
            :key="index"
            @click="toNoticeDetails(item)"
          >
            <div class="flex-center">
              <span class="text-type">公告</span>
              <div class="span-text">{{ item.titile }}</div>
            </div>
            <van-icon class="arrow-icon" name="arrow" />
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>

    <!-- 预约办丧  殡葬服务 网上祭扫  进度查询-->
    <div class="service-container">
      <div
        v-for="(item, index) in serviceList"
        :key="index"
        class="item-box"
        @click="toPage(item)"
      >
        <img src="@/assets/images/s-reserve.png" />
        <div class="text">{{ item.name }}</div>
      </div>
    </div>
    <!-- 推荐礼厅 -->
    <div>
      <div class="decoration-title">
        <img src="../../assets/images/dec-left.png" />
        <div class="text">推荐礼厅</div>
        <img src="../../assets/images/dec-right.png" />
      </div>
      <div class="hall-container" ref="rolling">
        <div
          v-for="(item, index) in ceremonyHallList"
          :key="index"
          class="item"
          @click="toPageHall(item)"
          ref="demoItem"
        >
          <img v-if="item.image" :src="item.image">
          <img v-else  src="../../assets/images/screenshot.png" />
          <div class="text">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <!-- 政策宣传 -->
    <div>
      <div class="decoration-title">
        <img src="../../assets/images/dec-left.png" />
        <div class="text">政策宣传</div>
        <img src="../../assets/images/dec-right.png" />
      </div>
      <div class="policy-container">
        <div
          v-for="(item, index) in advertiseInformation"
          :key="index"
          class="line-item"
          @click="toDetails(item)"
        >
          <div>
            <div class="title-text">
              {{ item.advTitle }}
            </div>
            <div class="date-time">{{ item.publishDate }}</div>
          </div>
          <img :src="item.images" />
        </div>
        <div class="more-content" @click="getMorePolicy(0)">查看更多</div>
      </div>
    </div>

    <!-- 殡仪用品 -->
    <div style="margin-bottom: 30px">
      <div class="decoration-title">
        <img src="../../assets/images/dec-left.png" />
        <div class="text">殡仪用品</div>
        <img src="../../assets/images/dec-right.png" />
      </div>
      <div class="funeral-products">
        <div
          v-for="(item, index) in productList"
          :key="index"
          class="products-item"
          @click="toProductDetails(item)"
        >
          <img :src="item.picture" />
          <div class="products-name">
            {{ item.chargeName }}({{ item.specs }})
          </div>
          <div class="products-price">
            <span class="symbol">¥</span><span> {{ item.salesPrice }}</span>
          </div>
          <van-icon
            class="add-icon"
            name="add"
            @click.stop="addToShopcart(item)"
          ></van-icon>
        </div>
      </div>
      <div class="more-content" @click="toProductsPage">查看更多</div>
    </div>
  </div>
</template>
<script>
import { getToken } from "@/store/auth";
import { getImagesUrl,isMobile } from "@/utils/funeral.js";
import { geRecommendProduct } from "@/api/product.js";
import {showToast} from 'vant';
import {
  getBannerList,
  getMenusList,
  getNoticeList,
  getAdvertiseInfomation,
  getCeremonyHallList,
} from "@/api/index.js";
export default {
  name: "Home",
  data() {
    return {
      bannerList: [],
      entriesList: [],
      serviceList: [],
      noticeList: [],
      advertiseInformation: [],
      ceremonyHallList: [],
      productList: [],
      keyword: "",
      href:'',
    };
  },
  created() {
    this.makeCall()
    if (getToken()) {
      this.getBannerList();
      this.getMenusList();
      this.getNoticeList();
      this.getAdvertiseInfomation();
      this.getRCeremonyHallList();
      this.getProductsByCateId();
      if (!this.$store.state.user.name) {
        this.$store.dispatch("GetInfo");
      }
    } else {
      let { code } = { ...this.$route.query };
      this.$router.push({ path: "/wexin-login", query: { code: code } });
    }
  },
  mounted() {},
  beforeUnmount() {},
  methods: {
    // 获取banner
    async getBannerList() {
      let [err, res] = await this.$awaitWrap(getBannerList());
      if (err) {
        return;
      }
      this.bannerList = res.data;
      console.log(this.bannerList);
    },
    // 获取各个入口
    async getMenusList() {
      let [err, res] = await this.$awaitWrap(getMenusList());
      if (err) {
        return;
      }
      this.entriesList = res.data.entriesList;
      this.serviceList = res.data.serviceList;
    },
    // 通知公告
    async getNoticeList() {
      let [err, res] = await this.$awaitWrap(getNoticeList());
      if (err) {
        return;
      }
      this.noticeList = res.data;
      console.log(res);
    },
    // 政策宣传
    async getAdvertiseInfomation() {
      let [err, res] = await this.$awaitWrap(getAdvertiseInfomation());
      if (err) {
        return;
      }
      this.advertiseInformation = res.data.map((item) => {
        if (item.images) {
          item.images = getImagesUrl(item.images);
        }
        return item;
      });
      console.log(this.advertiseInformation);
    },
    // 推荐礼厅
    async getRCeremonyHallList() {
      let [err, res] = await this.$awaitWrap(getCeremonyHallList());
      if (err) {
        return;
      }
      this.ceremonyHallList = res.data.map((item)=>{
        if(item.image){
          item.image = getImagesUrl(item.image)
        }
        return item
      });
    },

    // 获取推荐商品
    async getProductsByCateId() {
      let [err, res] = await this.$awaitWrap(
        geRecommendProduct()
      );
      if (err) {
        return;
      }
      this.productList = res.data.map((item) => {
        item.cartNum = 1;
        if (item.productExtend.picture) {
          item.picture = getImagesUrl(item.productExtend.picture.split(',')[0]);
        }
        item.specs = item.productExtend.specs;
        return item;
      });
    },
    // 加入到购物车然后跳转到购物车
    addToShopcart(item) {
      let storeList = this.$store.state.product.shopCartList;
      if (storeList.length > 0) {
        let bool = storeList.some((rec) => {
          return rec.priceId == item.priceId;
        });
        if (bool) {
          storeList.forEach((product) => {
            if (product.priceId == item.priceId) {
              if (product.cartNum >= item.surplusStock) {
                showToast("该商品已全部加入购物车，没有剩余库存");
                return;
              } else {
                product.cartNum = product.cartNum + 1;
                showToast("加入购物车成功，请前去购物车查看");
              }
            }
          });
        } else {
          showToast("加入购物车成功，请前去购物车查看");
          storeList.push(item);
        }
      } else {
        showToast("加入购物车成功，请前去购物车查看");
        storeList.push(item);
      }
      this.$store.dispatch("setShopCartList", storeList);
    },
    // 跳转到购物车
    toProductDetailsPage() {
      this.$router.push({ path: "/product/cart" });
    },
    // 页面跳转
    toPage(item) {
      if (item.type) {
        this.$router.push({
          path: item.path,
          query: { publishMode: item.type },
        });
      } else {
        this.$router.push({ path: item.path });
      }
    },
    // 搜索页面
    toSearchPage() {
      this.$router.push({
        path: "/search/search",
        query: { keyword: this.keyword },
      });
    },

    // 政策宣传查看更多
    getMorePolicy(mode) {
      this.$router.push({ path: "/policy/list", query: { publishMode: mode } });
    },
    // 殡仪用品页面
    toProductsPage() {
      this.$router.push({ path: "/product/list" });
    },
    // 商品详情
    toProductDetails(item) {
      this.$router.push({
        path: "/product/details",
        query: { id: item.priceId },
      });
    },
    // 推荐礼厅跳转
    toPageHall(item) {
      window.location.href = item.url;
    },
    // 政策宣传详情
    toDetails(item) {
      this.$router.push({ path: "/policy/details", query: { id: item.id } });
    },
    // 公告详情
    toNoticeDetails(item) {
      this.$router.push({ path: "/noticeDetails", query: { id: item.id } });
    },
    // 拨打电话
    makeCall(){
      if(isMobile()){
        this.href = 'tel:0791-83875014';
      }
    },
    makeCallTips(){
      showToast('请使用手机端拨打电话')
    }
  },
};
</script>
<style lang="less">
.van-notice-bar {
  color: #78bca5 !important;
  background: transparent !important;
  position: relative;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  .text-type {
    font-weight: 800;
  }
  .span-text {
    color: #333333;
    margin-left: 8px;
    font-size: 12px;
    max-width: 60vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.van-notice-bar__content.van-ellipsis {
  width: 100%;
}

.van-swipe-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.arrow-icon {
  color: #333333;
}
</style>
