/*
 * @Author: yuwenwen
 * @Date: 2022-11-11 15:30:40
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-11-24 16:10:10
 * @FilePath: \nc-funeral-mobile\src\store\modules\product.js
 */
const product = {
    state: {
        shopCartList: [],
        shopcartNum:0
    },
    mutations: {
        SET_SHOPCARTLIST: (state, shopCartList) => {
            state.shopCartList = shopCartList;
        },
        SET_SHOPCARTNUM:(state, shopcartNum)=>{
            state.shopcartNum = shopcartNum;
        }
    },
    actions: {
        setShopCartList({
            commit,dispatch
        }, data) {
            commit('SET_SHOPCARTLIST', data);
            dispatch('getShopcartNum');
        },

        getShopcartNum({commit}){
            let list = this.state.product.shopCartList;
            console.log(list)
            var total = 0;
            list.forEach((item)=>{
                total = total + item.cartNum;
            });
            commit('SET_SHOPCARTNUM',total);
        }
    }
}


export default product;