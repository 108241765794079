<!--
 * @Author: yuwenwen
 * @Date: 2022-11-08 15:00:25
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-06-27 16:57:47
 * @FilePath: \nc-funeral-mobile\src\views\reserve\reserve.vue
-->
<template>
  <div>
    <div v-if="isLoading == 'success'" class="page-container reserve-container">
      <div
        class="reserve-list-item"
        v-for="(item, index) in reserveList"
        :key="index"
      >
        <div class="list-title ">
          <div class="list-title-name text-ellipse">逝者姓名：{{ item.deadName }}</div>
          <div
            class="cancel-btn"
            @click="cancelAppointment(item)"
            v-if="item.dealStatus != 2 && item.processingResults != 0"
          >
            取消预约
          </div>
        </div>
        <div class="list-container">
          <div>身份证号：{{ item.deadCardCode }}</div>
          <div>与逝者关系：{{relationship[item.relation]}}</div>
          <div>亡故时间：{{ item.deathDate }}</div>
          <div>预约时间：{{ item.appimentTime }}</div>
          <div>接运地址：{{ item.targetAddress }}</div>
          <div v-if="item.taskEndTime">接运时间：{{ item.taskEndTime }}</div>
          <div v-if="item.carNo">接运车牌：{{ item.carNo }}</div>
          <div v-if="item.staffs">司机名称：{{ item.staffs }}</div>
          <div v-if="item.processingMsg && item.processingResults != 2">
            处理意见：{{ item.processingMsg }}
          </div>
        </div>
        <div class="list-footer" @click="toReserve(item)">
          <div v-if="item.processingResults == 0">
            <span v-if="item.dealStatus == '1'">已派工</span>
            <span v-if="item.dealStatus == '3'">已完成</span>
          </div>
          <div v-if="item.processingResults == 1">不受理</div>
          <div v-if="item.processingResults == 2">未处理</div>
          <!-- <div v-if="item.dealStatus == 1" style="color:#28E9A7">{{ dealStatus[item.dealStatus] }}</div>
        <div v-if="item.dealStatus == 0" >
           <span v-if="item.processingResults=='0'">受理</span>
           <span v-if="item.processingResults=='1'">不受理</span>
           <span v-if="item.processingResults=='2'">未处理</span>
        </div>
        <div v-if="item.dealStatus == 2" >{{ dealStatus[item.dealStatus] }}</div>
        <div v-if="item.dealStatus == 3" >{{ dealStatus[item.dealStatus] }}</div> -->
          <van-icon class="icon" name="arrow"></van-icon>
        </div>
      </div>
    </div>

    <div
      v-if="isLoading == 'default'"
      style="text-align: center; height: 200px; line-height: 200px"
    >
      <van-loading color="#78BCA5" v-if="isLoading == 'default'" />
    </div>
    <div
      v-if="isLoading == 'none'"
      style="text-align: center; font-size: 16px; color: #aaa"
    >
      暂无数据~
    </div>
  </div>
</template>
<script>
import { getAppointmentList, cancelAppointment } from "@/api/complaint.js";
import { showFailToast, showConfirmDialog } from "vant";
import {getDicts} from '@/api/common.js';
export default {
  name: "Reserve",
  data() {
    return {
      reserveList: [],
      dealStatus: {
        0: "未处理",
        1: "已派工",
        2: "已取消",
        3: "已完成",
      },
      relationship:{},
      isLoading: "default",
      relationshipText:''
    };
  },
  created() {
    this.getRelationDict();
  },
  mounted() {
    this.getAppointmentLists();
    
  },
  methods: {
    // 获取与逝者关系数据字典
    getRelationDict(){
      getDicts('relation').then(res=>{
        console.log(res)
        res.map((item)=>{
          this.relationship[item.value] = item.text;
        })
      })
    },
    // 获取预约列表
    async getAppointmentLists() {
      let [err, res] = await this.$awaitWrap(getAppointmentList());
      if (err) {
        return;
      }
      if (res.result.records.length > 0) {
        this.isLoading = "success";
        this.reserveList = res.result.records;
      } else {
        this.isLoading = "none";
      }

      console.log(res);
    },
    // 取消预约
    cancelAppointment(item) {
      showConfirmDialog({
        title: "提示",
        message: "是否取消遗体接运申请操作",
      })
        .then(() => {
          cancelAppointment(item.infoId).then((res) => {
            if (res.code == 200) {
              this.getAppointmentLists();
            } else {
              showFailToast(res.message);
            }
          });
        })
        .catch(() => {});
    },
    // toReserve
    toReserve(item) {
      this.$router.push({
        path: "/reserve/add",
        query: { reserveId: item.infoId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.list-title-name{
  max-width: 240px
}
@import "../../assets/styles/reserve.less";
</style>