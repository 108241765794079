<!--
 * @Author: yuwenwen
 * @Date: 2022-11-09 17:34:05
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-06-27 16:05:50
 * @FilePath: \nc-funeral-mobile\src\views\reserve\reserveTransport.vue
-->

<template>
  <div class="reserve-transport" v-if="!isDisplayLocation">
    <van-form ref="transportFormRef" :disabled="isDisabled" :readonly="isDisabled">
      <div class="title-box">
        <div class="title-text">预约人信息</div>
      </div>
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          姓名
        </div>
        <van-field v-model="transportForm.name" name="请输入您的姓名" placeholder="请输入您的姓名" :maxlength="13"
          :rules="[{ required: true, message: '请输入您的姓名' }]" />
      </div>
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          电话
        </div>
        <van-field v-model="transportForm.phone" name="请输入电话" placeholder="请输入电话" :rules="[
          { required: true, message: '请输入电话' },
          { validator: validatePhone, message: '请输入正确格式电话号码' },
        ]" />
      </div>
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          预约类型
        </div>
        <van-field v-model="displayAppointmentType" name="picker" placeholder="请选择" 
          :rules="[{ required: true, message: '请选择预约类型' }]" is-link readonly @click="showPickerAppointmentTypeFuc" />

        <van-popup v-model:show="showPickerAppointmentType" position="bottom">
          <van-picker title="预约类型" :columns="appointmentTypeship" @confirm="appointmentTypeConfirm"
            @cancel="showPickerAppointmentType = false" />
        </van-popup>
      </div>
      <!-- 逝者信息 -->
      <div class="title-box">
        <div class="title-text">逝者信息</div>
      </div>
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          姓名
        </div>
        <van-field v-model="transportForm.deadName" name="请输入逝者姓名" placeholder="请输入逝者姓名" :maxlength="13"
          :rules="[{ required: true, message: '请输入逝者姓名' }]" />
      </div>
      <!-- 证件类型 -->
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          证件类型
        </div>
        <van-field v-model="dispalyCardType" name="picker" placeholder="请选择" is-link
          :rules="[{ required: true, message: '请选择证件类型' }]" readonly @click="showPickerCardTypeFun" />

        <van-popup v-model:show="showPickerCardType" position="bottom">
          <van-picker title="证件类型" :columns="cardTypeList" @confirm="cardTypeConfirm" @cancel="showPickerCardType = false" />
        </van-popup>
      </div>
      <!-- 证件号码 -->
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          证件号码
        </div>
        <van-field v-model.trim="transportForm.deadCardCode" name="请输入证件号码" placeholder="请输入证件号码"
          :rules="[{ required: true, message: '请输入证件号码' }]" />
      </div>
      <!-- 与逝者关系 -->
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          与逝者关系
        </div>
        <van-field v-model="displayRelation" name="picker" placeholder="请选择" is-link
          :rules="[{ required: true, message: '请选择与逝者关系' }]" readonly @click="showPickerFuc" />

        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker title="与逝者关系" :columns="relationship" @confirm="relationConfirm" @cancel="showPicker = false" />
        </van-popup>
      </div>
      <!-- 亡故日期 -->
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          亡故日期
        </div>
        <van-field v-model="valueDeadDate" name="请选择亡故日期" placeholder="请选择亡故日期"
          :rules="[{ required: true, message: '请选择亡故日期' }]" is-link  readonly @click="showPickerDateFuc" />

        <van-popup v-model:show="showPickerDate" position="bottom">
          <van-date-picker v-model="displayDeadDate" type="date" @cancel="showPickerDate = false" :max-date="new Date()"
            @confirm="deathDateConfirm" />
        </van-popup>
      </div>
      <!-- 亡故时间 -->
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          亡故时间
        </div>
        <van-field v-model="valueDeadTime" name="请选择亡故时间" placeholder="请选择亡故时间"
          :rules="[{ required: true, message: '请选择亡故时间' }]" is-link readonly @click="showPickerTimeFuc" />

        <van-popup v-model:show="showPickerTime" position="bottom">
          <van-time-picker v-model="displayDeadTime" type="date" @cancel="showPickerTime = false" :max-date="new Date()"
            @confirm="deathTimeConfirm" :columns-type="['hour', 'minute', 'second']" />
        </van-popup>
      </div>

      <!-- 车辆预约信息 -->
      <div class="title-box">
        <div class="title-text">车辆预约信息</div>
      </div>
      <!-- 预约日期 -->
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          预约日期
        </div>
        <van-field v-model="valueAppointDate" name="请选择预约日期" placeholder="请选择预约日期"
          :rules="[{ required: true, message: '请选择预约日期' }]" is-link readonly @click="showPickerReserveFuc" />
        <van-popup v-model:show="showPickerReserve" position="bottom">
          <van-date-picker type="date" v-model="displayAppointDate" @cancel="showPickerReserve = false"
            @confirm="reserveDateConfirm" :min-date="new Date()" />
        </van-popup>
      </div>
      <!-- 预约时间 -->
      <div class="line-item">
        <div class="label-text">
          <span>*</span>
          预约时间
        </div>
        <van-field v-model="valueAppointTime" name="请选择预约时间" placeholder="请选择预约时间"
          :rules="[{ required: true, message: '请选择预约时间' }]" is-link readonly @click="showPickerReserveTimeFuc" />
        <van-popup v-model:show="showPickerReserveTime" position="bottom">
          <van-time-picker type="date" v-model="displayAppointTime" @cancel="showPickerReserveTime = false"
            @confirm="reserveTimeConfirm" :min-date="new Date()" :columns-type="['hour', 'minute', 'second']" />
        </van-popup>
      </div>
      <!-- 接运地址 -->
      <div class="line-item" @click="getLocation">
        <div class="label-text">
          <span>*</span>
          接运地址
        </div>
        <van-field v-model="transportForm.targetAddress" name="请输入接运地址" placeholder="请输入接运地址"
          :rules="[{ required: true, message: '请输入接运地址' }]" />
      </div>

      <div class="line-item">
        <div class="label-text">备注</div>
        <van-field v-model="transportForm.deadNote" name="请输入备注" placeholder="请输入备注" />
      </div>

      <div class="line-item file-upload">
        <div class="label-text">死亡证明</div>
        <van-field name="uploader">
          <template #input>
            <FileUpload :disabled="isDisabled" :value="transportForm.deathCertFile" :bizPath="'reserve/pic'" @res="uploadRes"></FileUpload>
        </template>
        </van-field>
      </div>

      <!-- 提交按钮 -->
      <!-- 
                  预约状态-dealStatus：0-未处理 1-已派工 2-已取消
                  处理状态-processingResults：0-已处理 1-不处理 2-未处理
                 -->
      <div class="btn-box" v-if="!reserveId">
        <div class="btn" @click="addAppointment">提交</div>
      </div>
      <div class="btn-box" v-else-if="
        (transportForm &&
          transportForm.dealStatus == 0 &&
          transportForm.processingResults == 1) ||
        (transportForm && transportForm.dealStatus == 2)
      ">
        <div class="btn" @click="editAppointment">重新提交</div>
      </div>
    </van-form>
  </div>
  <!-- 地址选择 -->
  <location v-if="isDisplayLocation" @locationSelected="locationSelected"></location>
</template>
<script>
import {
  addAppointment,
  editAppointment,
  getAppointmentDetails,
} from "@/api/complaint.js";
import {getDicts} from '@/api/common.js';
import { parseTime } from "@/utils/funeral.js";
import FileUpload from "@/components/fileUpload.vue";
import { showFailToast, showSuccessToast } from "vant";
import Location from "../common/location.vue";
export default {
  name: "ReserveTransport",

  data() {
    return {
      relationship: [ ],
      appointmentTypeship: [{ text: "接运", value: 1 }],
      showPicker: false,
      showPickerCardType:false,
      showPickerDate: false,
      showPickerReserve: false,
      showPickerAppointmentType: false,
      displayRelation: "",
      dispalyCardType:'',
      displayDeadDate: "",
      valueDeadDate: "",
      displayAppointDate: "",
      valueAppointDate: "",
      displayAppointmentType: "接运",
      showPickerTime: false,
      showPickerReserveTime: false,
      displayDeadTime: "",
      valueDeadTime: "",
      displayAppointTime: "",
      valueAppointTime: "",
      transportForm: {
        relation: 1,
        deathDate: null,
        appimentTime: null,
      },
      reserveId: "",
      isDisplayLocation: false,
      cardTypeList:[]
    };
  },
  components: { FileUpload, Location },
  beforeRouteLeave(to, from) {
    if (this.isDisplayLocation) {
      if (to.path == "/home" &&from.path == "/reserve/add") {
        this.isDisplayLocation = false;
        this.$router.push({ path: "/reserve/add" });
      }
    }
  },
  created() {
    let { reserveId } = this.$route.query;
    this.reserveId = reserveId;
    this.transportForm.appointmentType = 1;
    // 与逝者关系数据字典
    this.getRelationDict();
    // 证件类型数据字典
    this.getCardTypeDict()
    // 详情
    if (reserveId) {
      this.getAppointData();
    } else {
      // 新增
      let currentDate = parseTime(new Date(), "{y}-{m}-{d}").split("-");
      this.displayDeadDate = currentDate;
      this.displayAppointDate = currentDate;
      let currentTime = parseTime(new Date(), "{h}:{i}:{s}").split(":");
      this.displayDeadTime = currentTime;
      this.displayAppointTime = currentTime;
    }
  },
  mounted() { },
  methods: {
    // 获取与逝者关系数据字典
    getRelationDict(){
      getDicts('relation').then(res=>{
        this.relationship = res;
      })
    },
    // 证件类型数据字典
    getCardTypeDict(){
      getDicts('cardType').then(res=>{
        this.cardTypeList = res;
      })
    },
    validatePhone(val) {
      const reg =
        /^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-8]|18[0-9]|19[0-9])\d{8}$/;
      return reg.test(val);
    },
    // 上传回调
    uploadRes(res) {
      console.log("上传回调===>", res);
      this.transportForm.deathCertFile = res.message;
      console.log(this);
    },
    // 获取预约详情
    async getAppointData() {
      let [err, res] = await this.$awaitWrap(
        getAppointmentDetails(this.reserveId)
      );
      if (err) {
        return;
      }
      this.transportForm = res.result;
      // 预约类型
      this.appointmentTypeship.map((item) => {
        if (item.value == this.transportForm.appointmentType) {
          this.displayAppointmentType = item.text;
        }
      });
      // 与逝者关系
      this.relationship.map((item) => {
        if (item.value == this.transportForm.relation) {
          this.displayRelation = item.text;
        }
      });
      // 证件类型
      this.cardTypeList.map((item)=>{
        if (item.value == this.transportForm.deadCardType) {
          this.dispalyCardType = item.text;
        }
      })

      // 亡故日期
      console.log(this.transportForm.deathDate.split(" ")[0]);
      this.valueDeadDate = this.transportForm.deathDate.split(" ")[0];
      this.displayDeadDate = this.valueDeadDate.split("-");
      this.valueDeadTime = this.transportForm.deathDate.split(" ")[1];
      this.displayDeadTime = this.valueDeadTime.split(":");
      // 预约日期
      this.valueAppointDate = this.transportForm.appimentTime.split(" ")[0];
      this.displayAppointDate = this.valueAppointDate.split("-");
      this.valueAppointTime = this.transportForm.appimentTime.split(" ")[1];
      this.displayAppointTime = this.valueAppointTime.split(":");
    },
    // 预约新增
    async addAppointment() {
      this.$refs.transportFormRef
        .validate()
        .then(() => {
          addAppointment(this.transportForm).then((res) => {
            if (res.code == 200) {
              showSuccessToast("预约成功");
              if (res.success) {
                this.$router.replace({ path: "/reserve/reserve" });
              }
            } else {
              showFailToast(res.message);
            }
          });
        })
        .catch(() => { });
    },
    // 预约修改
    async editAppointment() {
      this.$refs.transportFormRef
        .validate()
        .then(() => {
          editAppointment(this.transportForm).then((res) => {
            if (res.code == 200) {
              showSuccessToast("编辑成功");
              if (res.success) {
                this.$router.replace({ path: "/reserve/reserve" });
              }
            } else {
              showFailToast(res.message);
            }
          });
        })
        .catch(() => { });
    },

    // 选择接运地址
    getLocation() {
      if(this.isDisabled){
        return false
      }
      this.isDisplayLocation = true;
    },

    // 地址选择的结果
    locationSelected(item) {
      this.isDisplayLocation = false;
      this.transportForm.targetAddress = item.address;
      this.transportForm.lng = item.point.lng;
      this.transportForm.lat = item.point.lat;
    },
    // 预约类型-弹出
    showPickerAppointmentTypeFuc(){
      if(this.isDisabled){
        return
      }
      this.showPickerAppointmentType = true;
    },
    // 选择预约类型
    appointmentTypeConfirm(item) {
      console.log(item);
      this.displayAppointmentType = item.selectedOptions[0].text;
      this.transportForm.appointmentType = item.selectedOptions[0].value;
      this.showPickerAppointmentType = false;
    },
    // 选择证件类型
    showPickerCardTypeFun(){
      this.showPickerCardType = true
    },
    
    // 选择与逝者关系-弹出
    showPickerFuc(){
      if(this.isDisabled){
        return
      }
      this.showPicker = true;
    },
    // 选择与逝者关系
    relationConfirm(item) {
      this.displayRelation = item.selectedOptions[0].text;
      this.transportForm.relation = item.selectedOptions[0].value;
      this.showPicker = false;
    },
    // 选择证件类型
    cardTypeConfirm(item){
      this.dispalyCardType = item.selectedOptions[0].text;
      this.transportForm.deadCardType = item.selectedOptions[0].value;
      this.showPickerCardType = false;
    },

    // 选择亡故日期-弹出
    showPickerDateFuc(){
      if(this.isDisabled){
        return
      }
      this.showPickerDate = true;
    },
    // 选择亡故日期
    deathDateConfirm(time) {
      this.displayDeadDate = time.selectedValues;
      this.valueDeadDate = time.selectedValues
        .map((item) => {
          return item;
        })
        .join("-");
      this.showPickerDate = false;
    },
    // 选择亡故时间-弹出
    showPickerTimeFuc(){
      if(this.isDisabled){
        return
      }
      this.showPickerTime = true;
    },
    // 选择亡故时间
    deathTimeConfirm(time) {
      this.displayDeadTime = time.selectedValues;
      this.valueDeadTime = time.selectedValues
        .map((item) => {
          return item;
        })
        .join(":");
      this.transportForm.deathDate =
        this.valueDeadDate + " " + this.valueDeadTime;
      this.showPickerTime = false;
    },
    // 选择预约日期-弹出
    showPickerReserveFuc(){
      if(this.isDisabled){
        return
      }
      this.showPickerReserve = true;
    },
    // 选择预约日期
    reserveDateConfirm(time) {
      this.displayAppointDate = time.selectedValues;
      this.valueAppointDate = time.selectedValues
        .map((item) => {
          return item;
        })
        .join("-");
      this.transportForm.appimentTime =
        this.valueAppointDate + " " + this.valueAppointTime;
      this.showPickerReserve = false;
    },
    // 选择预约时间-弹出
    showPickerReserveTimeFuc(){
      if(this.isDisabled){
        return
      }
      this.showPickerReserveTime = true;
    },
    // 选择预约时间
    reserveTimeConfirm(time) {
      this.displayAppointTime = time.selectedValues;
      this.valueAppointTime = time.selectedValues
        .map((item) => {
          return item;
        })
        .join(":");
      this.transportForm.appimentTime =
        this.valueAppointDate + " " + this.valueAppointTime;
      this.showPickerReserveTime = false;
    },
  },
  computed: {
    isDisabled: function () {
      const val = !this.reserveId ||
        (this.transportForm &&
          this.transportForm.dealStatus == 0 &&
          this.transportForm.processingResults == 1) ||
        (this.transportForm && this.transportForm.dealStatus == 2)
        ? false
        : true
      return val;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.van-field__control {
  color: rgba(0, 0, 0, 1);
  font-size: 17px;
  font-family: PingFang-SC-Bold;
  text-align: right;
}

/deep/.van-field__control {
  color: rgba(0, 0, 0, 1);
  font-size: 17px;
  font-family: PingFang-SC-Bold;
  text-align: right;
}

/deep/.van-field__error-message {
  text-align: right;
}

.van-cell:after {
  border: 0;
}

.van-cell {
  padding: 0;
}

/deep/.van-uploader__upload {
  margin: 0;
}

@import "../../assets/styles/reserve.less";
</style>