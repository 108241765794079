/*
 * @Author: yuwenwen
 * @Date: 2022-11-15 17:38:13
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-12 16:56:47
 * @FilePath: \nc-funeral-mobile\src\api\complaint.js
 */
// 投诉建议相关
import request from "../utils/request";
const root = '/h5/bus/report/'


// 添加投诉建议
export function addComplaint(data) {
    return request({
        url: root + `add`,
        method: 'POST',
        data
    })
}
// 投诉建议列表
export function getComplaintList(params) {
    return request({
        url: root + `list`,
        method: 'GET',
        params
    })
}

// 投诉建议详情
export function getComplaintDetails(id) {
    return request({
        url: root + `queryById/${id}`
    })
}


// 预约接运
const appointmentRoot = 'h5/bus/appointment/';
// 预约接运新增
export function addAppointment(data) {
    return request({
        url: appointmentRoot + `add`,
        method: 'POST',
        data
    })
}
// 预约接运修改
export function editAppointment(data) {
    return request({
        url: appointmentRoot + `update`,
        method: 'PUT',
        data
    })
}
// 我的预约-列表
export function getAppointmentList() {
    return request({
        url: appointmentRoot + `list`,
        method: 'GET',
    })
}

// 预约详情
export function getAppointmentDetails(id) {
    return request({
        url: appointmentRoot + `queryById/${id}`,
        method: 'GET',
    })
}

// 取消预约
export function cancelAppointment(id) {
    return request({
        url: appointmentRoot + `cancel/${id}`,
        method: 'GET',
    })
}



/**图片上传
 * biz: deadInfo/pic
 * file: （二进制）
 */
export function imgUpload(data) {
    return request({
        url: `/h5/bus/common/upload`,
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryui4RXckKWBL7wP6c' //改这里就好了
        },
    })
}


