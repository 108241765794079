/*
 * @Author: yuwenwen
 * @Date: 2022-11-09 17:34:04
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-26 17:29:47
 * @FilePath: \nc-funeral-mobile\src\router\index.js
 */
/*
 * @Author: yuwenwen
 * @Date: 2022-11-08 14:52:34
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-11-10 14:35:26
 * @FilePath: \nc-funeral-mobile\src\router\index.js
 */
import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '../views/index/home.vue'
import Navigator from '../views/navigator/navigator.vue'
import Progress from '../views/progress/progress.vue'
import Reserve from '../views/reserve/reserve.vue'
import Mine from '../views/mine/mine.vue'
import ReserveTransport from '../views/reserve/reserveTransport.vue'

// import store from '@/store'
// import {
//   getToken
// } from '@/store/auth'

const routes = [{
    path: '/',
    redirect: '/home',
    component: () => import('../views/Index.vue'),
    children: [{
        path: '/home',
        name: 'Home',
        component: Home
      },
      // 导航
      {
        path: '/navigator/navigator',
        name: 'Navigator',
        component: Navigator
      },     
      // 预约
      {
        path: '/reserve/reserve',
        name: 'Reserve',
        component: Reserve
      },
      // 我的
      {
        path: '/mine/mine',
        name: 'Mine',
        component: Mine
      }
    ]
  },
  // 微信登录
  {
    path:'/wexin-login',
    name:'WeixnLogin',
    component:() => import('../views/wxLogin.vue'),
  },
  {
    path: '/progress/progress',
    name: 'Progress',
    component: Progress
  },
  //预约接运
  {
    path: '/reserve/add',
    name: 'ReserveTransport',
    component: ReserveTransport
  },
  // 政策列表
  {
    path: '/policy/list',
    name: 'PolicyList',
    component: () => import('../views/policy/list.vue')
  },
  // 政策详情
  {
    path: '/policy/details',
    name: 'PolicyDetails',
    component: () => import('../views/policy/details.vue')
  },
  // 举报列表
  {
    path: '/complaint/list',
    name: 'ComplaintList',
    component: () => import('../views/complaint/list.vue')
  },
  // 举报详情
  {
    path: '/complaint/details',
    name: 'ComplaintDetails',
    component: () => import('../views/complaint/details.vue')
  },
  // 投诉举报表单（添加，详情）
  {
    path: '/complaint/add',
    name: 'AddForm',
    component: () => import('../views/complaint/add.vue')
  },
  // 殡仪用品列表
  {
    path:'/product/list',
    name:'ProductList',
    component:() => import('../views/products/productsList.vue')
  },
  // 购物车
  {
    path:'/product/cart',
    name:'ShopCart',
    component:() => import('../views/products/shopCart.vue')
  },
  // 商品详情
  {
    path:'/product/details',
    name:'ProductDetails',
    component:() => import('../views/products/productDetails.vue')
  },
  // 我的订单
  {
    path:'/mine/my/order',
    name:'MyOrder',
    component:() => import('../views/mine/myOrder.vue')
  },
  {
    path:'/product/order/details',
    name:'OrderDetails',
    component:() => import('../views/products/orderDetails.vue')
  },
  // vr礼厅
  {
    path:'/mine/my/vrhall',
    name:'VrHall',
    component:() => import('../views/mine/vrHall.vue')
  },
  {
    // 遗体接运
    path: '/remainPickUp',
    name: 'remainPickUp',
    component: () => import('../views/remainPickUp')
  },
  {
    // 遗体接运 业务详情
    path: '/remainPickUp/details',
    name: 'remainPickUp/details',
    component: () => import('../views/remainPickUp/details')
  },
  {
    // 遗体接运 业务档案
    path: '/remainPickUp/businessFile',
    name: 'remainPickUp/businessFile',
    component: () => import('../views/remainPickUp/businessFile')
  },

  {
    // 遗体外运
    path: '/remainETansport',
    name: 'remainETansport',
    component: () => import('../views/remainETansport')
  },
  {
    // 遗体接运 业务详情
    path: '/remainETansport/details',
    name: 'remainETansport/details',
    component: () => import('../views/remainETansport/details')
  },
  {
    // 遗体接运 业务档案
    path: '/remainETansport/businessFile',
    name: 'remainETansport/businessFile',
    component: () => import('../views/remainETansport/businessFile')
  },
  {
    // 单位介绍
    path:"/company/introduce",
    name:'CompanyIntroduce',
    component:() => import('../views/index/companyIntroduce')
  },
  {
    // 首页公告详情
    path:"/noticeDetails",
    name:'noticeDetails',
    component:() => import('../views/index/noticeDetails')
  },
  // 开发中页面
  {
    path:'/error/isbuilding',
    name:'IsBuilding',
    component:() => import('../views/errorpage/isBuilding.vue')
  },
  // 搜索页面
  {
    path:'/search/search',
    name:'Search',
    component:() => import('../views/search/search.vue')
  },
  // 扫码页面
  {
    path:'/scan/scan',
    name:'ScanCodePage',
    component:() => import('../views/scan/scanCodePage.vue'),
  },
  // 电子讣告
  // 电子讣告列表
  {
    path:'/obituary/list',
    name:'ObituaryList',
    component:() => import('../views/obituary/obituaryList.vue'),
  },
  {
    path:'/obituary/template/elegant',
    name:'TemplateElegant',
    component:() => import('../views/obituary/template/templateElegant.vue'),
  },{
    path:'/obituary/template/refined',
    name:'TemplateRefined',
    component:() => import('../views/obituary/template/templateRefined.vue'),
  },{
    path:'/obituary/template/simple',
    name:'TemplateSimple',
    component:() => import('../views/obituary/template/templateSimple.vue'),
  },{
    path:'/obituary/add/content',
    name:'AddContent',
    component:() => import('../views/obituary/addContent.vue'),
    meta:{
      keepAlive:true
    }
  }
  // 讣告记录
  ,{
    path:'/obituary/records',
    name:'ObituaryRecords',
    component:() => import('../views/obituary/obituaryRecords.vue'),
  },
  // 留言页面
  {
    path:'/obituary/leave/message',
    name:'LeaveMessage',
    component:() => import('../views/obituary/leaveMessage.vue')
  },
  // 选择地址页面
  {
    path:'/location/selection',
    name:'Location',
    component:() => import('../views/common/location.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router