<!--
 * @Author: yuwenwen
 * @Date: 2022-12-14 11:14:53
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-06 11:52:06
 * @FilePath: \nc-funeral-mobile\src\views\common\location.vue
-->
<template>
  <div class="location-page">
    <!-- 搜索框 -->
    <div class="search-con">
      <van-field
        placeholder="请输入地址"
        left-icon="search"
        id="searchAddres"
      ></van-field>
    </div>
    <!-- 地图 -->
    <div id="map" class="map"></div>
    <!-- 附近地址 8条数据-->
    <div class="surroundings">
      <div
        v-for="(item, index) in surroundingPois"
        :key="index"
        class="surrounding-pois"
        @click="selectLocation(item)"
      >
        <div class="title">{{ item.title }}</div>
        <div class="details">{{ item.address }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Location",
  data() {
    return {
      surroundingPois: [],
      redirctPath: "",
    };
  },
  created() {
    let { path } = this.$route.query;
    this.redirctPath = path;
  },
  mounted() {
    this.$nextTick(() => {
      this.getMapInfo();
    });
  },
  methods: {
    getMapInfo() {
      let _this = this;
      let Bmap = window.BMapGL;
      this.map = new Bmap.Map("map");
      //    获取当前地理位置
      _this.getCurrentLocation();
    },

    // 获取当前地址位置
    getCurrentLocation() {
      let _this = this;
      let Bmap = window.BMapGL;
      var geolocation = new Bmap.Geolocation();
      // 获取当前地理位置为中心点
      geolocation.getCurrentPosition(function (r) {
        const point = new Bmap.Point(r.point.lng, r.point.lat);
        const marker = new Bmap.Marker(point); // 创建标注
        _this.map.addOverlay(marker);
        _this.map.centerAndZoom(point, 15);
        _this.map.enableScrollWheelZoom(true);
        _this.getGeoAddress(r.point);
      });

      //   地图点击事件
      this.map.addEventListener("click", function (e) {
        console.log(e);
        var clickpt = e.latlng; // 点击的坐标
        _this.map.clearOverlays(); // 移除地图上的标注
        var marker = new Bmap.Marker(clickpt); // 创建标注
        _this.map.addOverlay(marker); // 将标注添加到地图中
        // 逆地址解析
        _this.getGeoAddress(clickpt);
      });

      /** 搜索地址Start */
      // 建立一个自动完成的对象
      var ac = new Bmap.Autocomplete({
        input: "searchAddres",
        location: _this.map,
      });
      // 鼠标点击下拉列表后的事件
      ac.addEventListener("onconfirm", function (e) {
        _this.map.clearOverlays(); // 移除地图上的标注
        var local = new Bmap.LocalSearch(_this.map, {
          //智能搜索
          onSearchComplete: function (results) {
            let poi = results.getPoi(0); //获取第一个智能搜索的结果
            var searchpt = poi.point; // 获取坐标
            _this.map.centerAndZoom(searchpt, 16);
            _this.map.addOverlay(new Bmap.Marker(searchpt)); //添加标注
            _this.getGeoAddress(searchpt); // 逆地址解析
          },
        });
        // 搜索词
        var searchValue = e.item.value;
        local.search(
          searchValue.province +
            searchValue.city +
            searchValue.district +
            searchValue.street +
            searchValue.business
        );
      });
      /** 搜索地址End */
    },

    // 根据point 逆向解析地理位置
    getGeoAddress(point) {
      let _this = this;
      let Bmap = window.BMapGL;
      var geoc = new Bmap.Geocoder();
      geoc.getLocation(point, (res) => {
        console.log(res);
        _this.surroundingPois = res.surroundingPois;
      });
    },

    // 选择地址
    selectLocation(item) {
      this.$emit('locationSelected',item)
    },
  },
};
</script>
<style lang="less" scoped>
.location-page {
  width: 100vw;
  background: white;
  padding-top: 1px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
}

.search-con {
  height: 36px;
  background: rgb(245, 245, 245);
  width: 92vw;
  margin: 10px auto;
  border-radius: 18px;
  /deep/.van-field__control {
    text-align: left !important;
    font-size: 14px;
    background: transparent !important;
    color: #bbb;
  }
  .van-cell {
    background: none;
    line-height: 18px;
    color: #bbb;
  }
}

.map {
  width: 100vw;
  height: 220px;
}

.surroundings{
  height: calc(100vh - 282px);
  overflow: auto;
}

.surrounding-pois {
  height: 50px;
  font-size: 14px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    color: black;
  }
  .details {
    color: rgb(137, 137, 137);
    font-size: 12px;
  }
}
</style>