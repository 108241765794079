import { createStore } from 'vuex'

import user from './modules/user'
import product from './modules/product'
import getters from './getters'


export default createStore({
  modules: {
    user,
    product
  },
  getters
})
