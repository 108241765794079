/*
 * @Author: yuwenwen
 * @Date: 2022-11-08 14:52:34
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-14 16:47:35
 * @FilePath: \nc-funeral-mobile\src\main.js
 */
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import awaitWrap from '@/utils/awaitWrap'
// 引入适配器
import "amfe-flexible";

 // 桌面端适配
import '@vant/touch-emulator';
// 引入vant组件
import Vant from 'vant'
import {
    Lazyload
} from 'vant'
import 'vant/lib/index.css'


// 挂载
const app = createApp(App);
app.config.globalProperties.$awaitWrap = awaitWrap

app.use(store).use(router).use(Vant).use(Lazyload).mount('#app')