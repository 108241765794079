<!--
 * @Author: yuwenwen
 * @Date: 2022-11-17 10:58:13
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-06 15:53:10
 * @FilePath: \nc-funeral-mobile\src\components\fileUpload.vue
-->
<template>
  <van-uploader
    v-model="fileList"
    :upload-icon="uploadIcon"
    :after-read="fileAfterRead"
    :before-delete="beforeDelete"
    :before-read="beforeRead"
    :max-count="size"
    :disabled="disabled"
  >
  </van-uploader>
</template>
<script>
import { fileUpload } from "@/api/common.js";
import { getImagesUrl } from "@/utils/funeral.js";
import { showToast } from "vant";
export default {
  name: "FileUpload",
  data() {
    return {
      fileList: [],
    };
  },
  props: {
    value: {
      type: [String, Array],
      required: false,
    },
    size: {
      type: Number,
      default: 1,
    },
    bizPath: {
      type: String,
      default: "/h5/file",
    },
    uploadIcon: {
      type: String,
      default: require("../assets/images/upload-default.png"),
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 文件上传之后
    async fileAfterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData();
      formData.append("file", file.file);
      formData.append("biz", this.bizPath);
      console.log(formData);

      let [err, res] = await this.$awaitWrap(fileUpload(formData));
      if (err) {
        file.status = "failed";
        file.message = "上传失败";
        return;
      }
      console.log(res);
      file.status = "done";
      file.message = "";
      this.$emit("res", res);
    },
    // 文件删除
    beforeDelete(file, detail) {
      if(!this.disabled){
        this.fileList.splice(detail.index, 1);
      }
      
    },
    initFileList(paths) {
      if (!paths || paths.length == 0) {
        this.fileList = [];
        return;
      }

      let fileList = [];
      let arr = paths.split(",");
      for (var a = 0; a < arr.length; a++) {
        let url = getImagesUrl(arr[a]);
        fileList.push({
          // uid: uidGenerator(),
          // name: getFileName(arr[a]),
          status: "done",
          url: url,
          // response: {
          //   status: 'history',
          //   message: arr[a],
          // },
        });
      }
      this.fileList = fileList;
    },
    beforeRead(file) {
      let bool =
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "image/jpg" ||
        file.type == "image/bmp" ||
        file.type == "image/gif";
      if (!bool) {
        showToast("请上传图片");
        return false;
      }
      return true;
    },
  },
  computed: {},
  watch: {
    value: {
      handler(val) {
        if (val instanceof Array) {
          this.initFileList(val.join(","));
        } else {
          this.initFileList(val);
        }
      },
      //立刻执行handler
      immediate: true,
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.van-uploader__upload {
  background: transparent;
  i {
    font-size: 78px !important;
  }
}
</style>