// import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
  // return Cookies.get(TokenKey)
  //获取token
  return JSON.parse(window.localStorage.getItem(TokenKey));
}

export function setToken(token) {
  // return Cookies.set(TokenKey, token)
  //设置token
  window.localStorage.setItem(TokenKey,JSON.stringify(token))
  // console.log(JSON.stringify(token));
}

export function removeToken() {
  // return Cookies.remove(TokenKey)
  //清除token
  window.localStorage.removeItem(TokenKey)
  
}