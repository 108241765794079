<!--
 * @Author: yuwenwen
 * @Date: 2022-11-08 14:52:34
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-13 17:50:10
 * @FilePath: \nc-funeral-mobile\src\App.vue
-->
<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <keep-alive :include="keepAlive">
        <component :is="Component" v-if="$route.meta.keepAlive"></component>
      </keep-alive>
      <component :is="Component"  v-if="!$route.meta.keepAlive"/>
    </router-view>
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      keepAlive: [],
    };
  },
  watch: {
    $route(to, from) {
      if(from.name == 'AddContent' || to.name=='AddContent'){
        this.keepAlive = ["AddContent"]
      }else if(from.name=='ReserveTransport' || to.name=='ReserveTransport'){
        this.keepAlive = ['ReserveTransport']
      }else{
        this.keepAlive = []
      }
    },
  },
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }

    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>
<style>
html {
  height: 100%;
}
body,
#app {
  height: inherit;
}
</style>

