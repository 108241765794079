/*
 * @Author: yuwenwen
 * @Date: 2022-11-11 17:21:56
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-11-14 14:58:19
 * @FilePath: \nc-funeral-mobile\src\api\login.js
 */
// 登录相关
import request from "../utils/request";
const root = '/h5/login/'

// 获取公众号跳转地址
export function getRedirctUrl(){
    return request({
        url:root + `get/url`,
        method:'GET',
    })
}

// 获取微信openId和token
export function wxlogin(params){
    return request({
        url:root + `get/openid`,
        method:'GET',
        params,
        
    })
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
 export function localLogin(parameter) {
    return request({
      url: root + 'localLogin',
      method: 'post',
      params: parameter
    })
  }

// 获取用户信息
export function getUserInfo(){
    return request({
        url:root+`info`,
        method:'GET'
    })
}
