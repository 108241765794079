/*
 * @Author: yuwenwen
 * @Date: 2022-11-11 11:35:05
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-09 16:20:34
 * @FilePath: \nc-funeral-mobile\src\utils\request.js
 */
import axios from 'axios'

import { showFailToast } from "vant";
import router from '@/router/index'
import {
  getToken,
} from "@/store/auth";
const request = axios.create({
  baseURL: process.env.NODE_ENV === 'production'? process.env.VUE_APP_BASE_API:process.env.VUE_APP_BASE_API,
  timeout: 30000,//响应时间 
})
//请求拦截 interceptors拦截  request请求
request.interceptors.request.use(
  (config) => {
    let token = getToken()
    if(token){
      config.headers["token"] = token;
    }
   
    return config;
  },
  (err) => {
    //promise.reject返回一个被拒绝的Promise对象，通过使用err的实例获取错误的原因
    console.log('请求被拒绝：', err);
    return Promise.reject(err);
  },
)

// 响应拦截 interceptors拦截 response响应
request.interceptors.response.use(
  // 请求成功返回数据
  (res) => {
    if (res.data.code === 500) {
      showFailToast(res.data.message);
    }
    if (res.data.code === 401) {
      showFailToast(res.data.message);
      router.push("/wexin-login");
      window.localStorage.removeItem('token')
    }
    return res.data;
  },
  (err) => {
    console.log('响应失败：', err);
    if (err.response.data.code === 401) {
      // showFailToast('登录失效');
      router.push("/wexin-login");
      window.localStorage.removeItem('token')
    }
    return Promise.reject(err);
  }
)
export default request
