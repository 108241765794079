/*
 * @Author: yuwenwen
 * @Date: 2022-11-16 17:43:53
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-06 10:44:34
 * @FilePath: \nc-funeral-mobile\src\api\product.js
 */

// 商品相关
import request from "../utils/request";
const root = '/h5/bus/product/';
const orderRoot= '/h5/bus/order/';


// 商品分类
export function getProductCategory(){
    return request({
        url:root + `category`,
        method:'GET',
    })
}

// 分类商品
export function getProductByCateId(id){
    return request({
        url:root + `category/${id}`,
        method:'GET'
    })
}

// 获取商品详情
export function getProductDetails(id){
    return request({
        url:root + `${id}`,
        method:'GET'
    })
}

// 确认订单
export function orderConfirm(data){
    return request({
        url:orderRoot + `confirm`,
        method:'POST',
        data
    })
}

// 创建订单
export function orderCreate(data){
    return request({
        url:orderRoot + `create`,
        method:'POST',
        data
    })
}

// 取消订单
export function orderCancel(orderNo){
    return request({
        url: orderRoot + `cancel/${orderNo}`,
        method:'PUT'
    })
}

// 订单列表
export function getOrderList(params){
    return request({
        url:orderRoot + `list`,
        method:'GET',
        params
    })
}

// 订单信息
export function getOrderDetails(orderNo){
    return request({
        url:orderRoot + `${orderNo}`,
        method:'GET'
    })
}

// 重新支付
export function orderPay(){
    return request({
        url:orderRoot + `pay`,
        method:'GET',
    })
}


// 首页推荐商品
export function geRecommendProduct(){
    return request({
        url:root + `recommend`,
        method:'GET'
    })
}

