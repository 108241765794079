/*
 * @Author: yuwenwen
 * @Date: 2022-11-17 11:08:50
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-06-16 17:30:08
 * @FilePath: \nc-funeral-mobile\src\api\common.js
 */
import request from "../utils/request";

// 文件上传接口
export function fileUpload(data){
    return request({
        url:`/h5/bus/common/upload`,
        method:'POST',
        data,
    })
}

// 获取微信分享凭证
export function getWxTicket(url){
    return request({
        url:`/h5/bus/wechar/getTicket`,
        method:'GET',
        params:{
            url
        }
    })
}

// 扫一扫
export function scanning(deadId){
    return request({
        url:`/h5/bus/progress/scanning/${deadId}`,
        method:'GET'
    })
}

// 数据字典
export function getDicts(code){
    return request({
        url:`/h5/bus/common/getDictItems?dictCode=` + code,
        method:'GET'
    })
}