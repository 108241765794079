/*
 * @Author: yuwenwen
 * @Date: 2022-11-11 13:46:44
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-05 15:21:03
 * @FilePath: \nc-funeral-mobile\src\store\getters.js
 */
const getters = {
  sidebar: state => state.app.sidebar,
  token:state => state.user.token,
  obituaryContent:state => state.user.obituaryContent,
}
export default getters
