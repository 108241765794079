/*
 * @Author: yuwenwen
 * @Date: 2022-11-11 13:46:44
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-12 14:33:00
 * @FilePath: \nc-funeral-mobile\src\store\modules\user.js
 */
// userinfo

import {
  wxlogin, getUserInfo, localLogin
} from "@/api/login";
import awaitWrap from '@utils/awaitWrap'
import {
  getToken,
  setToken,
  removeToken
} from "@/store/auth";
const user = {
  state: {
    token: getToken(),
    name: "",
    staffId: '',    //员工id
    userInfo: {},
    isLogin: false,
    obituaryContent: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_STAFFID: (state, staffId) => {
      state.staffId = staffId;
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    TO_LOGIN: (state, isLogin) => {
      state.isLogin = isLogin
    },
    SET_OBITUARYCONTENT: (state, content) => {
      state.obituaryContent = content;
    }
  },

  actions: {
    /*用户微信登录*/
    async userLogin({ commit, dispatch }, { code }) {
      let [err, res] = await awaitWrap(wxlogin({ code: code }));
      if (err) {
        return;
      }
      dispatch("GetInfo");
      setToken(res.data.token);
      commit("SET_TOKEN", res.data.token);
      sessionStorage.setItem('Admin-openId', res.data.openid);
      return [null, res];
    },

    // 用户本地登录
    async localUserLogin({ commit, dispatch }, { parameter }) {
      let [err, res] = await awaitWrap(localLogin({
        userName: parameter.username,
        password: parameter.password,
      }));
      if (err) {
        return;
      }
      if(res.code === 500){
        return;
      }
      dispatch("GetInfo");
      setToken(res.data.token);
      commit("SET_TOKEN", res.data.token);
      return [null, res];
    },

   
    // 获取用户信息
    async GetInfo({
      commit,
    }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((res) => {
            if (!res) return
            const user = res.data;
            commit("SET_NAME", user.username);
            commit("SET_STAFFID", user.staffId);
            commit("SET_USER_INFO", user);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 前端 登出
    FedLogOut({
      commit
    }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    },

    //电子讣告内容填写预览
    setObituaryContent({ commit }, content) {
      commit('SET_OBITUARYCONTENT', content)
    }

  },
};

export default user;